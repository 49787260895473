import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import FooterForm from './FooterForm/FooterForm';

function Footer() {
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
   const { t, i18n } = useTranslation();

   const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
   };

   return (
      <footer className="footer">
         <div className="container">
            <div className="footer_wrap row">
               {!isMobile && (
                  <div className="col-xl-8">
                     <div className="footer_wrap-title">{t('footer.Оставить заявку')}</div>
                  </div>
               )}
               <div className="col-xl-4">
                  <div className="footer_wrap_left">
                     <div className="footer_wrap_left-title">
                        {!isMobile ? (
                           <>
                              {t('footer.Заполните форму,')} <br />
                              {t('footer.мы все внимательно')} <br />
                              {t('footer.изучим и выйдем на связь')}
                           </>
                        ) : (
                           <>
                              {t('footer.Заполните форму,')} <br />
                              {t('footer.мы все внимательно')}
                              {t('footer.изучим и выйдем на связь')}
                           </>
                        )}
                     </div>
                     {!isMobile && (
                        <div className="footer_wrap_left_manager">
                           <div className="footer_wrap_left_manager-avatar">
                              <img src="./img/footer/manager.jpg" alt="Ира Шаталюк" />
                           </div>
                           <div className="footer_wrap_left_manager_meta">
                              <p>
                                 {t('footer.Ира Шаталюк,')} <br />
                                 {t('footer.проект менеджер')}
                              </p>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
               <div className="col-xl-4">
                  <div className="footer_wrap_right">
                     <FooterForm />
                     <div className="footer_wrap_right_bot row">
                        <div className="footer_wrap_right_bot_social">
                           <a href="https://t.me/kata_one" className="footer_wrap_right_bot_social-it" target="_blank" rel="nofollow noreferrer noopener" aria-label="Telegram" title="Telegram">
                              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                 <path d="M20.8906 0C15.3306 0.0289587 10.009 2.2617 6.09287 6.20859C2.17669 10.1555 -0.0144081 15.4944 7.13089e-05 21.0544C0.0145507 26.6145 2.23343 31.9418 6.17011 35.8683C10.1068 39.7947 15.4399 41.9997 21 41.9997C26.5601 41.9997 31.8932 39.7947 35.8299 35.8683C39.7666 31.9418 41.9855 26.6145 41.9999 21.0544C42.0144 15.4944 39.8233 10.1555 35.9071 6.20859C31.991 2.2617 26.6694 0.0289587 21.1094 0M15.0938 23.5156C13.5625 24.5 12.6875 24.3906 11.375 24.0625C10.0625 23.7344 9.07812 23.4063 7.76562 22.9688C6.45312 22.5313 6.01562 21.5469 8.3125 20.6719C20.4896 15.349 27.5625 12.3958 29.5312 11.8125C31.6094 11.1563 32.1562 12.0313 31.9375 13.6719C31.4271 18.0469 30.5521 23.5521 29.3125 30.1875C28.9844 32.0469 28.1094 33.5781 25.375 31.7188L18.5938 27.125C17.5729 26.3958 17.3906 25.6667 18.0469 24.9375C19.4688 23.2969 21.9844 21.3281 25.375 17.8281C25.7396 17.4635 25.849 17.1719 25.7031 16.9531C25.5573 16.7344 25.2656 16.7708 24.8281 17.0625" fill="#8A8A8A" />
                              </svg>
                           </a>
                           <a href="https://wa.link/d4en74" className="footer_wrap_right_bot_social-it" target="_blank" rel="nofollow noreferrer noopener" aria-label="Whatsapp" title="Whatsapp">
                              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                 <path fillRule="evenodd" clipRule="evenodd" d="M0 20.9995C0 9.41963 9.41752 0 20.9956 0L20.9921 0.0010447H21.0043C32.5819 0.0010447 41.9998 9.42102 41.9998 21.0005C41.9998 32.58 32.5823 42 21.0043 42C16.7334 42 12.7708 40.7286 9.45096 38.5311L1.38194 41.1112L3.99915 33.3082C1.48122 29.8512 0 25.5917 0 20.9995ZM13.8007 9.62002C14.4178 9.65941 14.7395 9.69487 15.1334 10.6706L15.136 10.6549C15.264 10.9641 15.4387 11.3883 15.6307 11.8544C16.1969 13.229 16.9133 14.9682 17.0241 15.1987C17.1725 15.5073 17.3209 15.9407 17.1108 16.3347C16.9249 16.7301 16.7612 16.9184 16.4828 17.2388C16.4663 17.2578 16.4493 17.2773 16.432 17.2973C16.3022 17.4469 16.1749 17.5813 16.0481 17.7152C15.8723 17.9009 15.6974 18.0856 15.5181 18.3085C15.5129 18.3146 15.5077 18.3207 15.5024 18.3269C15.2229 18.6553 14.9194 19.0119 15.2713 19.6178C15.6297 20.2219 16.8639 22.2364 18.6785 23.8517C20.8298 25.7672 22.6084 26.4865 23.4108 26.8111C23.4826 26.8401 23.5466 26.866 23.6023 26.8892C24.1078 27.0994 24.7131 27.0468 25.0833 26.6529C25.4921 26.2131 25.9866 25.5178 26.4983 24.7985C26.5734 24.6928 26.6489 24.5867 26.7246 24.4807C27.1448 23.8885 27.6752 23.8136 28.2306 24.0237C28.7991 24.222 31.798 25.6902 32.4151 26.012C32.5731 26.0944 32.7173 26.1643 32.8468 26.2271C33.2232 26.4096 33.4763 26.5323 33.5877 26.7277C33.7373 26.9904 33.7373 28.2117 33.2305 29.6431C32.7237 31.0746 30.7122 32.2604 29.1077 32.6058C28.0087 32.8409 26.5775 33.0261 21.7509 31.026C16.0833 28.6794 12.2688 23.1882 11.4439 22.0008C11.3705 21.895 11.3207 21.8234 11.2955 21.7912C11.2877 21.7805 11.2785 21.7681 11.2682 21.7539C10.8837 21.2299 8.80078 18.391 8.80078 15.4574C8.80078 12.5313 10.2451 11.0622 10.8935 10.4026C10.9127 10.3831 10.9311 10.3643 10.9489 10.3462C11.4557 9.83014 12.296 9.59375 13.0969 9.59375L13.8007 9.62002Z" fill="#8A8A8A" />
                              </svg>
                           </a>
                           <a href="https://www.instagram.com/kata_agency/" className="footer_wrap_right_bot_social-it" target="_blank" rel="nofollow noreferrer noopener" aria-label="Instagram" title="Instagram">
                              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                 <path d="M27.2587 11.1719H14.7408C12.7428 11.1719 11.1172 12.7974 11.1172 14.7955V27.3134C11.1172 29.3115 12.7428 30.9375 14.7408 30.9375H27.2587C29.2568 30.9375 30.8828 29.3119 30.8828 27.3134V14.7955C30.8828 12.7974 29.2572 11.1719 27.2587 11.1719ZM20.9998 27.5614C17.4122 27.5614 14.4933 24.6425 14.4933 21.0544C14.4933 17.4669 17.4122 14.548 20.9998 14.548C24.5878 14.548 27.5067 17.4669 27.5067 21.0544C27.5067 24.642 24.5873 27.5614 20.9998 27.5614ZM27.7159 15.8932C26.8666 15.8932 26.1759 15.2026 26.1759 14.3538C26.1759 13.5049 26.8666 12.8143 27.7159 12.8143C28.5647 12.8143 29.2554 13.5049 29.2554 14.3538C29.2554 15.2026 28.5647 15.8932 27.7159 15.8932Z" fill="#8A8A8A" />
                                 <path d="M20.9996 17.3008C18.9286 17.3008 17.2422 18.9862 17.2422 21.0573C17.2422 23.1293 18.9286 24.8152 20.9996 24.8152C23.0716 24.8152 24.7566 23.1293 24.7566 21.0573C24.7566 18.9867 23.0711 17.3008 20.9996 17.3008Z" fill="#8A8A8A" />
                                 <path d="M21 0C9.40248 0 0 9.40248 0 21C0 32.5975 9.40248 42 21 42C32.5975 42 42 32.5975 42 21C42 9.40248 32.5975 0 21 0ZM33.6321 27.3156C33.6321 30.8302 30.7735 33.6887 27.259 33.6887H14.741C11.2269 33.6887 8.3679 30.8302 8.3679 27.3156V14.7977C8.3679 11.2835 11.2269 8.42452 14.741 8.42452H27.259C30.7735 8.42452 33.6321 11.2835 33.6321 14.7977V27.3156Z" fill="#8A8A8A" />
                              </svg>
                           </a>
                        </div>
                        <div className="footer_wrap_right_bot_info">
                           {i18n.language === 'ru-RU' || i18n.language === 'ru' ? (
                              <a href="tel:+79615925417">{t('footer.Номер')}</a>
                           ) : (
                              <a href="tel:+995591034362">{t('footer.Номер')}</a>
                           )}
                           <button type="button" onClick={() => changeLanguage(i18n.language === 'ru-RU' || i18n.language === 'ru' ? 'en-US' : 'ru-RU')}>
                              {t('footer.Кнопка перевода')}
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer >
   );
}

export default Footer;